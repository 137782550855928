<template>
    <transition
    enter-active-class="duration-200 ease-over-out"
    enter-from-class="opacity-0 translate-y-4 scale-90"
    enter-to-class="opacity-100 translate-y-0 scale-100"
    leave-active-class="duration-200 ease-out delay-0"
    leave-from-class="opacity-100 translate-y-0 scale-100"
    leave-to-class="opacity-0 translate-y-4 scale-90">
        <slot />
    </transition>
</template>